import { Route } from 'react-router-dom';

import { RouteId } from '~/utils/router';

export const tbmRoutes = (
  <Route id={RouteId.Tbm} path=":projectId/tbm">
    <Route id={RouteId.TbmList} index path="list" lazy={() => import('pages/tbm/tbm.list.page')} />
    <Route
      id={RouteId.TbmDetail}
      index
      path=":tbmId"
      lazy={() => import('pages/tbm/tbm.detail.page')}
    />
  </Route>
);
