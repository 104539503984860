import { Route } from 'react-router-dom';

import { RouteId } from '~/utils/router';

export const safetyTrainingRoutes = (
  <Route id={RouteId.SafetyTraining} path=":projectId/safety-training">
    <Route
      id={RouteId.SafetyTrainingList}
      index
      path="list"
      lazy={() => import('pages/safety-training/safety-training.list.page')}
    />
    <Route
      id={RouteId.SafetyTrainingDetail}
      index
      path=":safetyTrainingId"
      lazy={() => import('pages/safety-training/safety-training.detail.page')}
    />
  </Route>
);
