import { Route } from 'react-router-dom';

import { RouteId } from '~/utils/router';

export const boardRoutes = (
  <Route id={RouteId.Board} path=":projectId/board">
    <Route
      id={RouteId.BoardList}
      index
      path="list"
      lazy={() => import('pages/board/board.list.page')}
    />
    <Route
      id={RouteId.BoardDetail}
      index
      path=":boardId"
      lazy={() => import('pages/board/board.detail.page')}
    />
  </Route>
);
