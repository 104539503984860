import axios from 'axios';

/**
 * Singleton 패턴으로 생성된 axios instance입니다.
 * baseURL과 액세스 토큰을 적용하는 코드가 반영되어 있으며,
 * 액세스 토큰은 XMLHttpRequest 시마다 새로 찾아오기 때문에 시점적으로 유리합니다.
 */
const ApiClient = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_SERVER || 'https://worker.api.caas.works/v1',
  };
  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(async (request) => {
    if (request.headers.Authorization) return request;
    const token = window.localStorage.getItem('JWT_TOKEN');
    if (token) {
      request.headers!.Authorization = `Bearer ${token}`;
    }
    return request;
  });

  return instance;
};

export default ApiClient();
