import { ReactNode, createContext } from 'react';

import { useQuery } from '@tanstack/react-query';

import apiClient from '~/utils/api/apiClient';
import { useProjectParams } from '~/utils/hooks';

interface PurchaseProviderProps {
  children: ReactNode;
}

interface PurchaseContextValues {
  hasWorkerManagement?: boolean;
  hasSafetyManagement?: boolean;
}

export const PurchaseContext = createContext<PurchaseContextValues>({});

export const PurchaseProvider = (props: PurchaseProviderProps) => {
  const { children } = props;
  const { projectId } = useProjectParams();
  const fetchWorkerData = async () => {
    const { data } = await apiClient.get(
      `/worker-managements/projects/${projectId}/check-purchase-status`,
    );
    return data;
  };
  const fetchSafetyData = async () => {
    const { data } = await apiClient.get(
      `/safety-managements/projects/${projectId}/check-purchase-status`,
    );
    return data;
  };
  const { data: workerData } = useQuery({
    queryKey: ['fetch-worker-management-data', projectId],
    queryFn: fetchWorkerData,
    enabled: !!projectId,
  });
  const { data: safetyData } = useQuery({
    queryKey: ['fetch-safety-management-data', projectId],
    queryFn: fetchSafetyData,
    enabled: !!projectId,
  });

  return (
    <PurchaseContext.Provider
      value={{
        hasSafetyManagement: safetyData,
        hasWorkerManagement: workerData,
      }}
    >
      {children}
    </PurchaseContext.Provider>
  );
};
