import { PropsWithChildren, useMemo } from 'react';

import { ConfigProvider } from 'antd-mobile';
import enUS from 'antd-mobile/es/locales/en-US';
import koKR from 'antd-mobile/es/locales/ko-KR';
import zhCN from 'antd-mobile/es/locales/zh-CN';
import zhTW from 'antd-mobile/es/locales/zh-TW';

import { useLanguage } from '~/utils/hooks';

export type AntdMobileProviderProps = PropsWithChildren<unknown>;

export const AntdMobileProvider = (props: AntdMobileProviderProps) => {
  const { children } = props;
  const { languageKey } = useLanguage();

  const locale = useMemo(() => {
    switch (languageKey) {
      case 'ko':
        return koKR;
      case 'zh_cn':
        return zhCN;
      case 'zh_tw':
        return zhTW;
      case 'en':
      default:
        return enUS;
    }
  }, [languageKey]);

  return <ConfigProvider locale={locale}>{children}</ConfigProvider>;
};
